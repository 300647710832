// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/noah-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/noah-bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/noah-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/noah-regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/fonts/itc-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("assets/fonts/itc-bold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("assets/fonts/itc-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("assets/fonts/itc-regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:300;font-style:normal}@font-face{font-family:itc;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:itc;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-weight:300;font-style:normal}.o1[data-v-0639bfce]{opacity:.1}.o2[data-v-0639bfce]{opacity:.2}.o3[data-v-0639bfce]{opacity:.3}.o4[data-v-0639bfce]{opacity:.4}.o5[data-v-0639bfce]{opacity:.5}.o6[data-v-0639bfce]{opacity:.6}.o7[data-v-0639bfce]{opacity:.7}.o8[data-v-0639bfce]{opacity:.8}.o9[data-v-0639bfce]{opacity:.9}a[data-v-0639bfce]{text-decoration:none}.divider[data-v-0639bfce]{height:2px;width:80px}.arrows[data-v-0639bfce]{font-size:1.4em}.left-slide-enter-active[data-v-0639bfce],.left-slide-leave-active[data-v-0639bfce],.right-slide-enter-active[data-v-0639bfce],.right-slide-leave-active[data-v-0639bfce]{transition:.5s}.left-slide-leave-to[data-v-0639bfce],.right-slide-enter[data-v-0639bfce]{transform:translate(100%)}.left-slide-enter[data-v-0639bfce],.right-slide-leave-to[data-v-0639bfce]{transform:translate(-100%)}.carousel[data-v-0639bfce]{position:relative}.carousel .slider[data-v-0639bfce]{position:relative;min-height:60vh;width:auto;overflow:hidden}.carousel .slider .item[data-v-0639bfce]{position:absolute;top:0;left:0;right:0;bottom:0;min-height:60vh}", ""]);
// Exports
module.exports = exports;
