<template>
  <section class="structure">
    <v-container>
      <v-row align="center" class="flex-sm-nowrap">
        <v-col cols="11" sm="3" md="3" class="pr-3 pr-sm-0 pt-0 pt-sm-8 mr-8 content">
          <span class="caption text-uppercase grey--text text--darken-1">Structuur</span>
          <page-content :content="text"/>
          <v-btn
            :href="dashboardUrl('/registreren')"
            large
            depressed
            outlined
            class="mt-5 px-8 caption d-none d-sm-inline-flex"
          >
            Aan de slag
          </v-btn>
        </v-col>
        <structure-slider :items="modules"/>
        <v-col
          class="d-sm-none text-center"
        >
          <v-btn
            :href="dashboardUrl('/registreren')"
            large
            depressed
            outlined
            color="accent"
            class="mt-3 px-10 caption"
          >
            Aan de slag
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageContent from '../PageContent';
import StructureSlider from './StructureSlider';

export default {
  components: {
    StructureSlider,
    PageContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    modules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="sass">
.structure
  z-index: 0
  position: relative

  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    margin-bottom: 150px
    margin-top: 150px

  &::before
    z-index: -1
    position: absolute
    content: 'Structuur'
    font-size: 7em
    font-weight: 700
    font-family: $heading-font-family
    top: -.7em
    left: 0
    bottom: 0
    right: 0
    color: var(--v-background-lighten1)
    overflow: hidden

    @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
      font-size: 11.875rem

.content
  z-index: 1

</style>
