<template>
  <section class="insights">
    <v-container class="py-12">
      <v-row class="align-center pb-12">
        <v-col cols="11" sm="10" md="5" class="content ml-md-n8">
          <span class="caption text-uppercase grey--text text--darken-1">Inzicht</span>
          <page-content :content="text"/>
          <div class="line py-9 pl-12">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="261px"
              height="94px"
              viewBox="0 0 261 94"
              version="1.1"
            >
              <!-- Generator: sketchtool 57.1 (101010) - https://sketch.com -->
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Sales---Salespagina-v2" transform="translate(-219.000000, -2072.000000)">
                  <g id="Voordelen" transform="translate(-13.000000, 1640.000000)">
                    <g id="icon-devices" transform="translate(232.000000, 432.000000)">
                      <g id="mobile" transform="translate(223.000000, 27.000000)">
                        <path id="Path" d="M6.6875,6.66666667 C6.69077219,3.53849997 9.06734372,1.00349034 12,1 L7.3125,1 C4.37984372,1.00349034 2.00327219,3.53849997 2,6.66666667 L2,9 L6.6875,9 L6.6875,6.66666667 Z" fill="var(--v-accent-base)" opacity="0.296433222"/>
                        <rect
                          id="Rectangle"
                          fill="var(--v-accent-base)"
                          opacity="0.296433222"
                          x="2"
                          y="11"
                          width="4"
                          height="44"
                        />
                        <path id="Path" d="M6.6875,59.9 L6.6875,56 L2,56 L2,59.9 C2.00327219,62.71535 4.37984372,64.9968587 7.3125,65 L12,65 C9.06734372,64.9968587 6.69077219,62.71535 6.6875,59.9 L6.6875,59.9 Z" fill="var(--v-accent-base)" opacity="0.296433222"/>
                        <path id="Shape" d="M31.0634921,0 L6.93650794,0 C3.10737174,0.0043439061 0.00432126551,3.12365233 0,6.97285068 L0,60.0271493 C0.00432126551,63.8763477 3.10737174,66.9956561 6.93650794,67 L31.0634921,67 C34.8926283,66.9956561 37.9956787,63.8763477 38,60.0271493 L38,6.97285068 C37.9956787,3.12365233 34.8926283,0.0043439061 31.0634921,0 Z M31.0634921,65.1809955 L6.93650794,65.1809955 C4.10626188,65.177821 1.81268173,62.872224 1.80952381,60.0271493 L1.80952381,56.0859729 L36.1904762,56.0859729 L36.1904762,60.0271493 C36.1873183,62.872224 33.8937381,65.177821 31.0634921,65.1809955 Z M36.1904762,9.09502262 L34.0793651,9.09502262 C33.5796789,9.09502262 33.1746032,9.50222066 33.1746032,10.0045249 C33.1746032,10.5068291 33.5796789,10.9140271 34.0793651,10.9140271 L36.1904762,10.9140271 L36.1904762,54.2669683 L1.80952381,54.2669683 L1.80952381,10.9140271 L26.3888889,10.9140271 C26.8885751,10.9140271 27.2936508,10.5068291 27.2936508,10.0045249 C27.2936508,9.50222066 26.8885751,9.09502262 26.3888889,9.09502262 L1.80952381,9.09502262 L1.80952381,6.97285068 C1.81268173,4.12777599 4.10626188,1.822179 6.93650794,1.81900452 L31.0634921,1.81900452 C33.8937381,1.822179 36.1873183,4.12777599 36.1904762,6.97285068 L36.1904762,9.09502262 Z" fill="var(--v-accent-base)"/>
                        <path id="Path" d="M31.2211164,9 L29.7788836,9 C29.3487181,9 29,9.44771525 29,10 C29,10.5522847 29.3487181,11 29.7788836,11 L31.2211164,11 C31.6512819,11 32,10.5522847 32,10 C32,9.44771525 31.6512819,9 31.2211164,9 Z" fill="#E43939"/>
                        <path id="Shape" d="M19.5,64 C21.4329966,64 23,62.4329966 23,60.5 C23,58.5670034 21.4329966,57 19.5,57 C17.5670034,57 16,58.5670034 16,60.5 C16.0022043,62.4320828 17.5679172,63.9977957 19.5,64 Z M19.5,59 C20.3284271,59 21,59.6715729 21,60.5 C21,61.3284271 20.3284271,62 19.5,62 C18.6715729,62 18,61.3284271 18,60.5 C18.0009185,59.6719536 18.6719536,59.0009185 19.5,59 Z" fill="#E43939"/>
                      </g>
                      <g id="tablet" transform="translate(133.000000, 14.000000)">
                        <path id="Path" d="M54.1663026,0 L6.83369741,0 C3.06558243,0 0,3.07650248 0,6.85804002 L0,10.9823312 C0,11.6294074 0.522735277,12.1540048 1.16751476,12.1540048 C1.81229425,12.1540048 2.33502953,11.6294074 2.33502953,10.9823312 L2.33502953,6.85804002 C2.33502953,4.36862414 4.35311771,2.34334724 6.83369741,2.34334724 L54.1663026,2.34334724 C56.6468823,2.34334724 58.6649705,4.36862414 58.6649705,6.85804002 L58.6649705,73.14196 C58.6649705,75.6313759 56.6468823,77.6566528 54.1663026,77.6566528 L6.83369741,77.6566528 C4.35311771,77.6566528 2.33502953,75.6313759 2.33502953,73.14196 L2.33502953,15.6690256 C2.33502953,15.0219494 1.81229425,14.497352 1.16751476,14.497352 C0.522735277,14.497352 0,15.0219494 0,15.6690256 L0,73.14196 C0,76.9234975 3.06558243,80 6.83369741,80 L54.1663026,80 C57.9344176,80 61,76.9234975 61,73.14196 L61,6.85804002 C61,3.07650248 57.9344176,0 54.1663026,0 Z" fill="var(--v-accent-base)" transform="translate(30.500000, 40.000000) rotate(-180.000000) translate(-30.500000, -40.000000) "/>
                        <path id="Path" d="M6,9.18138737 L6,53.8186126 C6,54.4710535 6.50552495,55 7.12907596,55 C7.75262698,55 8.25815193,54.4710535 8.25815193,53.8186126 L8.25815193,10.3627747 L53.7418481,10.3627747 L53.7418481,26.5641636 C53.7418481,27.2166044 54.247373,27.7455509 54.870924,27.7455509 C55.4944751,27.7455509 56,27.2166044 56,26.5641636 L56,9.18138737 C56,8.5289465 55.4944751,8 54.870924,8 L7.12907596,8 C6.5053744,8 6,8.5289465 6,9.18138737 Z" fill="var(--v-accent-base)"/>
                        <path id="Path" d="M6,66.8378162 C6,67.4796516 6.50552495,68 7.12907596,68 L54.870924,68 C55.4944751,68 56,67.4796516 56,66.8378162 L56,31.1621838 C56,30.5203484 55.4944751,30 54.870924,30 C54.247373,30 53.7418481,30.5203484 53.7418481,31.1621838 L53.7418481,65.6756325 L8.25815193,65.6756325 L8.25815193,57.9737632 C8.25815193,57.3319278 7.75262698,56.8115794 7.12907596,56.8115794 C6.50552495,56.8115794 6,57.3319278 6,57.9737632 L6,66.8378162 Z" fill="var(--v-accent-base)"/>
                        <path id="Shape" d="M27,72.5 C27,74.4299939 28.5700061,76 30.5,76 C32.4299939,76 34,74.4299939 34,72.5 C34,70.5701593 32.4298407,69 30.5,69 C28.5700061,69 27,70.5701593 27,72.5 Z M31.7022059,72.5 C31.7022059,73.1629902 31.162837,73.7022059 30.5,73.7022059 C29.8370098,73.7022059 29.2977941,73.1629902 29.2977941,72.5 C29.2977941,71.837163 29.8370098,71.2977941 30.5,71.2977941 C31.162837,71.2977941 31.7022059,71.837163 31.7022059,72.5 Z" fill="#E43939"/>
                        <path id="Path" d="M35.7800911,6 C36.4538061,6 37,5.55226667 37,5 C37,4.44773333 36.4538061,4 35.7800911,4 L30.2199089,4 C29.5461939,4 29,4.44773333 29,5 C29,5.55226667 29.5461939,6 30.2199089,6 L35.7800911,6 Z" fill="#E43939"/>
                        <circle id="Oval" fill="#E43939" cx="26" cy="5" r="1"/>
                      </g>
                      <path id="Combined-Shape" d="M135,24 L135,21.6666667 C135.003192,18.6147967 137.265325,16.1275022 140.098934,16.0047451 L140.3125,16 L145,16 C142.138872,16.0034052 139.807033,18.4163469 139.691949,21.4388631 L139.6875,21.6666667 L139.6875,24 L140,24 L140,83 L139.6875,83 L139.6875,86.9 C139.690772,89.71535 142.067344,91.9968587 145,92 L145,92 L140.3125,92 C137.379844,91.9968587 135.003272,89.71535 135,86.9 L135,86.9 L135,83 L135,21.6666667 Z" fill="var(--v-accent-base)" opacity="0.296433222"/>
                      <g id="computer" fill="var(--v-accent-base)">
                        <path id="Shape" d="M98.236077,0 L30.944879,0 C30.0870979,0 29.392053,0.699888914 29.392053,1.56318251 C29.392053,2.4264761 30.0870979,3.12636501 30.944879,3.12636501 L98.236077,3.12636501 C100.804658,3.12636501 102.894555,5.22999182 102.894555,7.81591253 L102.894555,63.0881701 L92.6459033,63.0881701 C91.7883292,63.0881701 91.0930773,63.7880591 91.0930773,64.6513527 C91.0930773,65.5146462 91.7883292,66.2145352 92.6459033,66.2145352 L102.894555,66.2145352 L102.894555,72.6834012 C102.894555,75.2693219 100.804658,77.3729487 98.236077,77.3729487 L24.552826,77.3729487 C23.6950449,77.3729487 23,78.0728377 23,78.9361312 C23,79.7994248 23.6950449,80.4993138 24.552826,80.4993138 L38.9237132,80.4993138 L36.1818366,90.873635 L30.944879,90.873635 C30.0870979,90.873635 29.392053,91.5735239 29.392053,92.4368175 C29.392053,93.3001111 30.0870979,94 30.944879,94 L75.3515623,94 C76.2093434,94 76.9043883,93.3001111 76.9043883,92.4368175 C76.9043883,91.5735239 76.2093434,90.873635 75.3515623,90.873635 L70.1146048,90.873635 L67.3727281,80.4993138 L98.2358699,80.4993138 C102.517115,80.4993138 106,76.9931996 106,72.6834012 L106,7.81591253 C106,3.50611415 102.517322,0 98.236077,0 Z M66.9010831,90.873635 L39.3953582,90.873635 L42.1372349,80.4993138 L64.1589994,80.4993138 L66.9010831,90.873635 Z"/>
                        <path id="Path" d="M18.10867,77.8543913 L7.79149028,77.8543913 C5.21385752,77.8543913 3.11659611,75.737675 3.11659611,73.1356637 L3.11659611,66.626546 L86.4417019,66.626546 C87.3025058,66.626546 88,65.9223022 88,65.0536369 C88,64.1849715 87.3025058,63.4807277 86.4417019,63.4807277 L3.11659611,63.4807277 L3.11659611,7.86454598 C3.11659611,5.26253473 5.21385752,3.14581839 7.79149028,3.14581839 L24.5232482,3.14581839 C25.384052,3.14581839 26.0815462,2.44157451 26.0815462,1.5729092 C26.0815462,0.704243877 25.384052,0 24.5232482,0 L7.79149028,0 C3.49515865,0 0,3.52793047 0,7.86454598 L0,73.135454 C0,77.4720695 3.49515865,81 7.79149028,81 L18.10867,81 C18.9694739,81 19.6669681,80.2957561 19.6669681,79.4270908 C19.6669681,78.5586352 18.9694739,77.8543913 18.10867,77.8543913 Z"/>
                      </g>
                      <rect
                        id="Rectangle"
                        fill="#E43939"
                        transform="translate(44.556349, 34.556349) rotate(-45.000000) translate(-44.556349, -34.556349) "
                        x="24.0563492"
                        y="33.0563492"
                        width="41"
                        height="3"
                        rx="1.5"
                      />
                      <rect
                        id="Rectangle-Copy"
                        fill="#E43939"
                        transform="translate(62.345120, 34.556349) rotate(-45.000000) translate(-62.345120, -34.556349) "
                        x="41.8451202"
                        y="33.0563492"
                        width="41"
                        height="3"
                        rx="1.5"
                      />
                      <path id="Combined-Shape-Copy" d="M3,10 L3,7.66666667 C3.00319238,4.61479672 5.26532524,2.1275022 8.09893418,2.00474515 L8.3125,2 L13,2 C10.1388719,2.00340521 7.80703332,4.41634692 7.69194858,7.43886312 L7.6875,7.66666667 L7.6875,10 L8,10 L8,69 L7.6875,69 L7.6875,72.9 C7.69077219,75.71535 10.0673437,77.9968587 13,78 L13,78 L8.3125,78 C5.37984372,77.9968587 3.00327219,75.71535 3,72.9 L3,72.9 L3,69 L3,7.66666667 Z" fill="var(--v-accent-base)" opacity="0.30061849"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <v-btn
            :href="dashboardUrl('/registreren')"
            large
            outlined
            color="primary"
          >
            Ontdek de mogelijkheden
          </v-btn>
        </v-col>
        <v-col
          class="align-end d-none d-md-flex"
          cols="5"
          md="7"
        >
          <v-img v-if="image" :src="image" :aspect-ratio="3/2" class="device outside-container"/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageContent from '~/components/PageContent';

export default {
  components: {PageContent},
  props: {
    text: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="sass">
.insights
  z-index: 0
  position: relative

  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    margin-bottom: 150px
    margin-top: 150px

  &::before
    z-index: -1
    position: absolute
    content: 'Inzicht'
    font-size: 7em
    font-weight: 700
    font-family: $heading-font-family
    top: -.4em
    left: 0
    bottom: 0
    right: 0
    color: var(--v-background-lighten1)
    overflow: hidden

    @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
      font-size: 11.875rem

.line
  position: relative
  &::before
    position: absolute
    left: 0
    content: ''
    height: 94px
    opacity: 0.2
    border-left: 4px solid var(--v-primary-base)
  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    left: 8%

.device
  box-shadow: 0 0 0 20px black, 0 0 0 45px var(--v-background-lighten1), 0 0 0 46px var(--v-background-lighten5)
  border-radius: 5px

.outside-container
  transform: translateX(calc((100vw - 800px) / 2))

  @media screen and (min-width: map-get($grid-breakpoints, 'lg'))
    transform: translateX(calc((100vw - 1100px) / 2))

</style>
