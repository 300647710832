<template>
  <v-col class="carousel pb-9">
    <v-row justify="start" no-gutters>
      <v-col class="shrink text-no-wrap">
        <v-btn
          :disabled="currentPosition === 0"
          :ripple="false"
          @click="prev"
          icon
          text
          color="primary"
          class="mr-3"
        >
          <font-awesome-icon :icon="['far', 'long-arrow-alt-left']"/>
        </v-btn>
        <v-btn :disabled="offset === maxOffset" :ripple="false" @click="next" icon text color="primary">
          <font-awesome-icon :icon="['far', 'long-arrow-alt-right']"/>
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-touch:swipe.left="next"
      v-touch:swipe.right="prev"
    >
      <v-row
        ref="carousel"
        :style="{ 'transform': `translateX(-${offset}px)` }"
        class="flex-nowrap carousel-items"
      >
        <v-col
          v-for="item in items"
          :key="item.id"
          cols="10"
          sm="6"
          md="5"
          lg="4"
          xl="3"
        >
          <v-card class="card background lighten-3 px-4 py-6">
            <v-card-title class="title text-no-wrap">
              <component
                :is="icon(item.title)"
                v-bind="appName === 'Bossify' ? {style:{'--v-primary-base': $vuetify.theme.themes.dark.warning}} : {}"
                class="mr-3"
              />
              {{ item.title }}
            </v-card-title>
            <v-card-text class="body-2 pt-4">
              {{ item.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import IconDashboard from '~/assets/img/icons/dashboard_color.svg?inline';
import IconAgenda from '~/assets/img/icons/agenda_color.svg?inline';
import IconBestellingen from '~/assets/img/icons/bestellingen_color.svg?inline';
import IconCalculaties from '~/assets/img/icons/calculaties_color.svg?inline';
import IconRelaties from '~/assets/img/icons/relaties_color.svg?inline';
import IconFacturen from '~/assets/img/icons/facturen_color.svg?inline';
import IconOffertes from '~/assets/img/icons/offertes_color.svg?inline';
import IconMiddelen from '~/assets/img/icons/middelen_color.svg?inline';

export default {
  components: {
    IconDashboard,
    IconAgenda,
    IconBestellingen,
    IconCalculaties,
    IconFacturen,
    IconOffertes,
    IconRelaties,
    IconMiddelen,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPosition: 0,
      width: 0,
      maxOffset: 0,
    };
  },
  computed: {
    offset() {
      const offset = this.currentPosition * this.width;
      if (offset < this.maxOffset) {
        return offset;
      } else {
        return this.maxOffset;
      }
    },
  },
  mounted() {
    const carousel = this.$refs.carousel;
    this.width = carousel.firstChild && carousel.firstChild.clientWidth;
    this.maxOffset = carousel.scrollWidth - carousel.clientWidth;
  },
  methods: {
    next() {
      this.currentPosition = (this.currentPosition + this.items.length + 1) % this.items.length;
    },
    prev() {
      this.currentPosition = (this.currentPosition + this.items.length - 1) % this.items.length;
    },
    icon(title) {
      if (title.includes('offerte')) {
        return 'IconOffertes';
      }
      if (title.includes('factu')) {
        return 'IconFacturen';
      }
      if (title.includes('calculatie')) {
        return 'IconCalculaties';
      }
      if (title.includes('agenda')) {
        return 'IconAgenda';
      }
      if (title.includes('relatie')) {
        return 'IconRelaties';
      }
      if (title.includes('middel')) {
        return 'IconMiddelen';
      }
      if (title.includes('bestelling')) {
        return 'IconBestellingen';
      }

      return 'IconDashboard';
    },
  },
};
</script>

<style lang="sass" scoped>
.card
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3)
  border-radius: 10px

.carousel-items
  transition: transform .5s

.carousel
  --width: 100%
  position: relative
  overflow: hidden
  flex: 0 0 var(--width)
  max-width: var(--width)

  @media screen and (min-width: map-get($grid-breakpoints, 'sm'))
    --width: calc((100% / 12 * 9) + ((100vw - #{map-get($grid-breakpoints, 'sm')}) / 2))

  @media screen and (min-width: map-get($grid-breakpoints, 'md'))
    --width: calc((100% / 12 * 9) + ((100vw - #{map-get($grid-breakpoints, 'md')}) / 2))

  @media screen and (min-width: map-get($grid-breakpoints, 'lg'))
    --width: calc((100% / 12 * 9) + ((100vw + 16px - #{map-get($grid-breakpoints, 'lg')}) / 2))

  // @media screen and (min-width: map-get($grid-breakpoints, 'xl'))
    --width: calc((100% / 12 * 9) + ((100vw + 16px - #{map-get($grid-breakpoints, 'xl')}) / 2))

  &::before
    z-index: -1
    position: absolute
    content: ''
    top: 0
    right: 0
    left: 30%
    bottom: 0
    border-radius: 10px 0 0 10px
    background-color: #1b1b1f

</style>
