<template>
  <v-container fluid class="text-center partners pt-12 black--text white">
    <v-container>
      <v-row class="justify-center">
        <v-col cols="12" sm="10" md="7" lg="6" class="mb-5 mt-sm-12 mt-0">
          <page-content :content="headingText"/>
        </v-col>
      </v-row>
      <div :style="{transform: `translateX(calc(${currentPosition} * ${width}))`}" class="slider">
        <v-row
          v-touch="{
            left: prev,
            right: next,
          }"
          class="carousel d-flex-inline flex-nowrap"
          justify="center"
        >
          <v-col
            v-for="item in items"
            :key="item.title"
            cols="6"
            sm="2"
            justify="center"
            fill-height
          >
            <a v-if="item.image" :href="item.shop_url" target="_blank" rel="noopener nofollow">
              <v-img :src="item.image" :alt="item.title" contain class="partner-image"/>
            </a>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="9"
          md="8"
          lg="7"
        >
          <page-content :content="text"/>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Touch from 'vuetify/es5/directives/touch';
import PageContent from '~/components/PageContent';

export default {
  components: {
    PageContent,
  },
  directives: {
    Touch,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
    headingText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPosition: 0,
      width: this.$breakpoint.smAndUp ? '(100% * (2/12))' : '(100% * (6/12))',
      partners: this.items.length,
    };
  },
  methods: {
    next() {
      // Only slide right if there are still items to the right
      if (this.currentPosition >= -Math.round(this.partners / 2) && this.currentPosition < Math.round(this.partners / 2)) {
        this.currentPosition += 1;
      }
    },
    prev() {
      // Only slide left if there are still items to the left
      if (this.currentPosition > -Math.round(this.partners / 2) && this.currentPosition <= Math.round(this.partners / 2)) {
        this.currentPosition -= 1;
      }
    },
  },
};
</script>

<style scoped lang="sass">
.partners
  overflow: hidden

.slider
  transition: transform .5s

.partner-image
  height: 150px
  width: auto

</style>
