<template>
  <v-container class="pt-0">
    <v-row class="justify-center">
      <span class="line"/>
      <v-col
        cols="11"
        sm="9"
        lg="7"
        justify="center"
        class="pt-11 pt-sm-20 pl-6"
      >
        <page-content :content="text" class="intro__text"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageContent from '~/components/PageContent';

export default {
  components: {
    PageContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="sass">
.line
  border-left: 1px dashed rgba(81, 177, 94, 0.4)
  position: relative
  height: 60px
  left: 0
  box-shadow: 0 0 8px 0 var(--v-accent-darken3)
  z-index: 1

  &::before
    content: ''
    position: absolute
    height: 16px
    width: 16px
    left: 0
    bottom: 0
    border-radius: 50%
    transform: translate(-50%, 50%)
    background-color: var(--v-accent-base)
    border: 4px solid var(--v-accent-darken3)
    box-shadow: 0 0 10px 0 var(--v-accent-darken3)
    z-index: 2

.index
  z-index: 1

.intro__text
  ::v-deep p
    margin-top: 15px
    font-weight: 700

@media screen and (min-width: map-get($grid-breakpoints, 'sm'))
  .pt-sm-20
    padding-top: 80px !important

  .line
    height: 100px
    left: 0

    &::before
      height: 15px
      width: 15px
      transform: translate(-50%, 50%)
      border: 4px solid var(--v-accent-darken3)

</style>
