<template>
  <section class="testimonials">
    <v-container class="py-sm-12 py-6">
      <v-row class="pt-sm-12 pt-8" justify="space-between" align="end">
        <v-col tag="h3" class="display-3">
          Lees wat klanten<br>over ons zeggen
        </v-col>
        <v-col
          class="text-no-wrap shrink"
        >
          <v-btn
            :disabled="currentPosition === 0"
            :ripple="false"
            @click="prev"
            icon
            text
            color="primary"
            class="mr-3"
          >
            <font-awesome-icon :icon="['far', 'long-arrow-alt-left']" size="2x"/>
          </v-btn>
          <v-btn :disabled="offset === maxOffset" :ripple="false" @click="next" icon text color="primary">
            <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" size="2x"/>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row
            ref="testimonials"
            v-touch="{
              left: next,
              right: prev,
            }"
            :style="{transform: `translateX(-${offset}px)`}"
            class="flex-nowrap pt-5 pb-12 testimonials-content"
          >
            <v-col v-for="item in items" :key="item.title" cols="10" sm="8" md="5" lg="4">
              <v-card class="card background lighten-3 pa-sm-6 pa-2 fill-height">
                <v-card-title class="row align-center">
                  <v-col cols="3" sm="2">
                    <v-avatar>
                      <v-img v-if="item.image" :src="item.image" :aspect-ratio="1" alt="avatar"/>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" sm="10" class="pl-sm-6">
                    <h4 class="caption">{{ item.name }}</h4>
                    <h5 class="caption text-uppercase grey--text text--darken-1">{{ item.functie }}</h5>
                  </v-col>
                </v-card-title>
                <v-card-text class="row">
                  <v-col :class="{'overline': $breakpoint.is.xsOnly, 'body-2': $breakpoint.is.smAndUp}" class="font-italic white--text text-none">
                    {{ item.testimonial }}
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Touch from 'vuetify/es5/directives/touch';

export default {
  directives: {
    Touch,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentPosition: 0,
      width: 0,
      maxOffset: 0,
    };
  },
  computed: {
    offset() {
      const offset = this.currentPosition * this.width;
      if (offset < this.maxOffset) {
        return offset;
      } else {
        return this.maxOffset;
      }
    },
  },
  mounted() {
    const testimonials = this.$refs.testimonials;
    this.width = testimonials.firstChild.clientWidth;
    this.maxOffset = testimonials.scrollWidth - testimonials.clientWidth;
  },
  methods: {
    prev() {
      console.log('slide');
      this.currentPosition = (this.currentPosition + this.items.length - 1) % this.items.length;
    },
    next() {
      console.log('slide');
      this.currentPosition = (this.currentPosition + this.items.length + 1) % this.items.length;
    },
  },
};
</script>

<style scoped lang="sass">
.testimonials
  overflow: hidden

.card
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5)
  border-radius: 10px

.testimonials-content
  transition: transform .5s

</style>
