<template>
  <v-img
    v-if="image"
    :src="image"
    :srcset="imageSet || undefined"
    :aspect-ratio="$breakpoint.is.xs ? 2/3 : 2.3"
    cover
    class="align-end align-sm-center pb-3"
  >
    <v-container class="pb-12 pb-sm-0">
      <v-row justify="end">
        <v-col cols="11" sm="10" md="8" lg="6" class="text-right">
          <div v-html="text" class="display-3 text-right"/>
          <v-btn
            :href="dashboardUrl('/registreren')"
            large
            color="primary"
            class="white--text mt-5"
          >
            Probeer het nu gratis
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageSet: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-image {
  height: 475px;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: auto;
  }
}
</style>
