import {mapState} from 'vuex';

export default {
  async fetch({store, route, error}) {
    try {
      await store.dispatch('getPage', route.path.replace(/^\//, ''));
    } catch (e) {
      const statusCode = e.response && e.response.status >= 500 ? 503 : 404;

      error({statusCode, message: e.message});
    }
  },
  computed: mapState(['page']),
  head() {
    return {
      title: this.page.meta.title ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
        this.page.meta.index !== undefined
          ? {
            hid: 'robots',
            name: 'robots',
            content: (this.page.meta.follow ? 'follow ' : 'nofollow ') + (this.page.meta.index ? 'index' : 'noindex'),
          }
          : {},
        this.page.meta.title
          ? {
            hid: 'og:title',
            name: 'og:title',
            content: this.page.meta.title,
          } : {},
        this.page.meta.description
          ? {
            hid: 'og:description',
            name: 'og:description',
            content: this.page.meta.description,
          }
          : {},
        this.page.image
          ? {
            hid: 'og:image',
            name: 'og:image',
            content: this.page.image,
          }
          : {},
      ],
    };
  },
};
