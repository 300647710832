<template>
  <v-container class="trial py-sm-12 py-2" fluid>
    <v-container>
      <v-row align="center" justify="center" class="py-8">
        <v-col cols="12" sm="8" md="10" lg="9">
          <div class="text-center text-sm-left">
            <page-content :content="text"/>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="2" lg="3" class="text-center text-sm-right">
          <v-btn
            :href="dashboardUrl('/registreren')"
            x-large
            outlined
            color="white"
            class="px-12"
          >
            Meld je aan
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import PageContent from './PageContent';

export default {
  components: {
    PageContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};

</script>

<style scoped lang="sass">
.trial
  background-image: radial-gradient(circle at 50% 0, #5DDB8C, var(--v-primary-base))
</style>
