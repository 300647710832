// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/noah-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/noah-bold.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/fonts/noah-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/fonts/noah-regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("assets/fonts/itc-bold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("assets/fonts/itc-bold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("assets/fonts/itc-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("assets/fonts/itc-regular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:noah;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:300;font-style:normal}@font-face{font-family:itc;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:700;font-style:normal}@font-face{font-family:itc;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\");font-weight:300;font-style:normal}.o1[data-v-7ba793aa]{opacity:.1}.o2[data-v-7ba793aa]{opacity:.2}.o3[data-v-7ba793aa]{opacity:.3}.o4[data-v-7ba793aa]{opacity:.4}.o5[data-v-7ba793aa]{opacity:.5}.o6[data-v-7ba793aa]{opacity:.6}.o7[data-v-7ba793aa]{opacity:.7}.o8[data-v-7ba793aa]{opacity:.8}.o9[data-v-7ba793aa]{opacity:.9}.card[data-v-7ba793aa]{box-shadow:0 0 12px 0 rgba(0,0,0,.3);border-radius:10px}.carousel-items[data-v-7ba793aa]{transition:transform .5s}.carousel[data-v-7ba793aa]{--width:100%;position:relative;overflow:hidden;flex:0 0 var(--width);max-width:var(--width)}@media screen and (min-width:600px){.carousel[data-v-7ba793aa]{--width:calc(75% + (100vw - 600px)/2)}}@media screen and (min-width:960px){.carousel[data-v-7ba793aa]{--width:calc(75% + (100vw - 960px)/2)}}@media screen and (min-width:1264px){.carousel[data-v-7ba793aa]{--width:calc(75% + (100vw - 1248px)/2)}}.carousel[data-v-7ba793aa]:before{z-index:-1;position:absolute;content:\"\";top:0;right:0;left:30%;bottom:0;border-radius:10px 0 0 10px;background-color:#1b1b1f}", ""]);
// Exports
module.exports = exports;
