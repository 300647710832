<template>
  <div v-if="content" class="content">
    <dynamic-text :html="content"/>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
.content::v-deep
  font-weight: 700

  [class^="col-"]
    padding-top: 0 !important
    padding-bottom: 0 !important

  h2, h3, h4, h5, h6
    margin-top: 1em

  p, ul, ol, h2, h3, h4, h5, h6
    margin-bottom: 14px

  p:first-child:not(:last-child)
    font-size: map-deep-get($headings, 'h5', 'size') !important
    font-weight: map-deep-get($headings, 'h5', 'weight')
    line-height: map-deep-get($headings, 'h5', 'line-height') !important
    letter-spacing: map-deep-get($headings, 'h5', 'letter-spacing') !important
    font-family: map-deep-get($headings, 'h5', 'font-family') !important

  ul
    list-style: none
    padding: 0
    margin-bottom: 16px

    li::before
      content: ''
      display: inline-block
      height: 1em
      width: 1em
      background-image: url('../assets/img/angle-right-regular.svg')
      background-size: contain
      background-repeat: no-repeat
      vertical-align: sub
</style>
