<template>
  <v-container class="white black--text fill-height" fluid>
    <v-container class="carousel">
      <div class="carousel-nav">
        <v-row no-gutters>
          <v-col cols="9">
            <span
              v-for="(item, index) in items"
              :key="item.id"
              :class="[index === currentUsp ? 'caption d-inline-block pr-2 d-sm-none' : 'grey--text text--lighten-1 caption d-inline-block pr-2 d-sm-none']"
              @click="changeUsp(index)"
            >0{{ index + 1 }}</span>
          </v-col>
          <v-col cols="3" class="arrows accent--text d-sm-none">
            <font-awesome-icon :icon="['far', 'long-arrow-alt-left']" @click="prev" class="arrow mx-1" size="lg"/>
            <font-awesome-icon :icon="['far', 'long-arrow-alt-right']" @click="next" class="arrow mx-1" size="lg"/>
          </v-col>
        </v-row>
      </div>
      <div
        v-touch="{
          left: next,
          right: prev,
        }"
        class="slider"
      >
        <transition-group :name="`${direction}-slide`">
          <div v-for="(item, index) in items" v-show="currentUsp === index" :key="item.id">
            <div class="item">
              <v-row align="center" class="justify-center">
                <v-col cols="12" sm="5" md="4" lg="3">
                  <span
                    v-for="(itemNum, indexNum) in items"
                    :key="itemNum.id"
                    :class="[indexNum === currentUsp ? 'caption pr-2 d-none d-sm-inline' : 'grey--text text--lighten-1 caption pr-2 d-none d-sm-inline']"
                    @click="changeUsp(indexNum)"
                    style="cursor: pointer"
                  >0{{ indexNum + 1 }}</span>
                  <h3 class="headline py-sm-5">{{ item.name }}</h3>
                  <span v-html="item.text" class="body-2 pb-sm-5"/>
                  <div class="divider accent d-none d-sm-block my-sm-8"/>
                  <v-btn
                    :href="dashboardUrl('/registreren')"
                    color="primary"
                    depressed
                    large
                    class="white--text d-none d-sm-inline-flex"
                  >
                    Probeer het gratis
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" offset-md="1" md="5">
                  <v-img v-if="item.image" :src="item.image" :aspect-ratio="1" :max-height="500" contain class="mt-5"/>
                </v-col>
              </v-row>
            </div>
          </div>
        </transition-group>
      </div>

      <v-row justify="center" class="d-sm-none">
        <v-btn
          :href="dashboardUrl('/registreren')"
          color="primary"
          depressed
          large
          class="my-10 white--text"
        >
          Probeer het gratis
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Touch from 'vuetify/es5/directives/touch';

export default {
  directives: {
    Touch,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentUsp: 0,
      direction: '',
    };
  },
  methods: {
    next() {
      this.direction = 'right';
      this.currentUsp = (this.currentUsp + this.items.length + 1) % this.items.length;
    },
    prev() {
      this.direction = 'left';
      this.currentUsp = (this.currentUsp + this.items.length - 1) % this.items.length;
    },
    changeUsp(index) {
      if (index < this.currentUsp) {
        this.direction = 'left';
      } else {
        this.direction = 'right';
      }
      this.currentUsp = index;
    },
  },
};
</script>

<style scoped lang="sass">
a
  text-decoration: none

.divider
  height: 2px
  width: 80px

.arrows
  font-size: 1.4em

.left-slide-leave-active,
.left-slide-enter-active,
.right-slide-leave-active,
.right-slide-enter-active
  transition: .5s

.right-slide-enter, .left-slide-leave-to
  transform: translate(100%)

.right-slide-leave-to, .left-slide-enter
  transform: translate(-100%)

.carousel
  position: relative

  .slider
    position: relative
    min-height: 60vh
    width: auto
    overflow: hidden

    .item
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      min-height: 60vh
</style>
