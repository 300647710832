<template>
  <div>
    <header-sales :text="page.subtitel" :image="page.header_image" :image-set="page.header_image_set"/>
    <intro :text="page.tekst"/>
    <client-only>
      <structure :text="page.structuur" :modules="page.modules"/>
    </client-only>
    <insights :text="page.inzicht" :image="page.extra_image"/>
    <suppliers :text="page.leveranciers_tekst" :heading-text="page.leveranciers_koptekst" :items="page.leveranciers"/>
    <usps :items="usps"/>
    <trial :text="page.meld_je_aan"/>
    <client-only>
      <testimonials :items="testimonials"/>
    </client-only>
  </div>
</template>

<script>
import page from '~/plugins/mixins/page';
import HeaderSales from '~/components/index/HeaderSales';
import Intro from '~/components/index/Intro';
import Insights from '~/components/index/Insights';
import Suppliers from '~/components/index/Partners';
import Usps from '~/components/index/Usps';
import Trial from '~/components/Login';
import Testimonials from '~/components/index/Testimonials';
import Structure from '~/components/index/Structure';

export default {
  layout: 'index',
  components: {
    HeaderSales,
    Intro,
    Structure,
    Insights,
    Suppliers,
    Usps,
    Trial,
    Testimonials,
  },
  mixins: [page],
  async asyncData({$axios, error}) {
    try {
      const [usps, testimonials] = await Promise.all([
        $axios.get('/usps'),
        $axios.get('/testimonials'),
      ]);

      return {
        usps: usps.data.data,
        testimonials: testimonials.data.data,
      };
    } catch (e) {
      error({statusCode: 503, message: e.message});
    }
  },
};
</script>
